(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            NOTIFICATION: 'Notification',
            NOTIFICATION_LIST: 'Notifications',
            MARK_AS_READ: 'Mark as Read',
            NOTIFICATION_NO_CONTENT: 'This notification has no content',

            NOTIFICATION_STATUS_ALL: 'All',
            NOTIFICATION_STATUS_READ: 'Read',
            NOTIFICATION_STATUS_UNREAD: 'Unread',
            NOTIFICATION_READ_STATUS: 'Read Status',

            CREATED_AT_START_DATE: 'Created At Start',
            CREATED_AT_END_DATE: 'Created At End',

            // types
            NOTIFICATION_TYPE_TASK: 'Tasks',
            NOTIFICATION_TYPE_ASSETS: 'Assets',
            NOTIFICATION_TYPE_JOB: 'Jobs',
            NOTIFICATION_TYPE_QUOTE: 'Quotes',
            NOTIFICATION_TYPE_ACTIONS: 'Actions',
            NOTIFICATION_TYPE_EXPORT: 'Exports',
            NOTIFICATION_TYPE_IMPORT: 'Imports',
            NOTIFICATION_TYPE_CONTRACT: 'Contracts',
            NOTIFICATION_TYPE_FILE: 'Files',
            NOTIFICATION_TYPE_SPILLAGE: 'Spillages',
            NOTIFICATION_TYPE_BULKACTION: 'Bulk Actions',
            NOTIFICATION_TYPE_AUDIT: 'Audits',
            NOTIFICATION_TYPE_ESCALATION: 'Escalations',
            NOTIFICATION_TYPE_SERVICEPROVIDER: 'Service Providers',
            NOTIFICATION_TYPE_SURVEY: 'Surveys',
            NOTIFICATION_TYPE_ADMINISTRATION: 'Administration'
        });
    }
})();
