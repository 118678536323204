(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteAcceptanceAcceptController', QuoteAcceptanceAcceptController);

    QuoteAcceptanceAcceptController.$inject = [
        '$state',
        'apiClient',
        'messenger',
        'quoteResponse',
        'quoteOptionCollectionResponse',
        'requestDataFactory',
        'jobGroupRequired',
        'jobGroupCollectionResponse',
        'statutoryTypeCollectionResponse',
        'serviceProviderResponse',
        'operativeResponse',
        'partnershipResponse',
        'priorityCollectionResponse',
        'priorityService',
        '$translate',
        'lodash',
        'p2pResponse',
        'p2pService',
        'accountingElementsResponse',
        'settingCollectionResponse',
        'jobRequisiteCollectionResponse',
        'documentTypesCollectionResponse',
        'userManager',
        'siteResponse',
        'siteAssociateTypesCollectionResponse',
        'elbSettings',
        'serviceProviderService',
        'locationCollectionResponse',
        'quoteRequestResponse',
        '$scope'
    ];

    function QuoteAcceptanceAcceptController(
        $state,
        apiClient,
        messenger,
        quoteResponse,
        quoteOptionCollectionResponse,
        requestDataFactory,
        jobGroupRequired,
        jobGroupCollectionResponse,
        statutoryTypeCollectionResponse,
        serviceProviderResponse,
        operativeResponse,
        partnershipResponse,
        priorityCollectionResponse,
        priorityService,
        $translate,
        lodash,
        p2pResponse,
        p2pService,
        accountingElementsResponse,
        settingCollectionResponse,
        jobRequisiteCollectionResponse,
        documentTypesCollectionResponse,
        userManager,
        siteResponse,
        siteAssociateTypesCollectionResponse,
        elbSettings,
        serviceProviderService,
        locationCollectionResponse,
        quoteRequestResponse
    ) {
        var vm = this;

        vm.quoteResponse = quoteResponse;
        vm.jobGroupChildSelectMandatory = settingCollectionResponse.jobGroupChildSelectMandatory;
        vm.displayOperativeField = userManager.hasPermission('site_permission_job_operative');
        vm.p2pCommentRequired = elbSettings.getSetting('general_p2p_new_format_client').value;
        vm.requiredDocumentType = documentTypesCollectionResponse;
        vm.selectedRequiredDocumentTypes = [];
        vm.certificateRequiredDocType = null;
        vm.siteAssociateType = null;
        vm.siteResponse = siteResponse;
        vm.isHelpdesk = userManager.hasPermission('site_permission_helpdesk');
        vm.approvers = [];
        vm.form = null;
        vm.quoteValueErrorMessage = null;

        vm.setForm = (form) => vm.form = form;

        if (serviceProviderResponse.hasOwnProperty('contractorApproved') && serviceProviderResponse.contractorApproved === false) {
            $state.go('^', {}, {reload: false}).then(function() {
                messenger.error('SERVICE_PROVIDER_NOT_CONTRACTOR_APPROVED');
            });
        }

        var canAccept = true;

        for (var quoteOption in quoteOptionCollectionResponse.quoteoptions) {
            if (quoteOptionCollectionResponse.quoteoptions[quoteOption].canAccept === false) {
                canAccept = false;
                break;
            }
        }

        // If already accepted redirect
        if (canAccept === false) {
            $state.go('^', {}, {reload: false}).then(function() {
                messenger.error('QUOTE_ALREADY_ACCEPTED');
            });
        }

        if (p2pResponse !== null) {
            vm.isP2PSite = !!p2pResponse.isP2PSite;
            vm.isValidP2PUser = vm.isP2PSite && p2pResponse.isValidP2PUser;
            vm.p2pResponse = p2pResponse;

            vm.maxCharacterCount = 40;
            vm.getIfLengthRequired = getIfLengthRequired;
        }

        vm.quoteOptionCollectionResponse = quoteOptionCollectionResponse;

        if (vm.isP2PSite) {
            vm.quoteOptionCollectionResponse.quoteoptions = quoteOptionCollectionResponse.quoteoptions
                .filter(function (option) {
                    return option.status !== 'accepted';
                });
            vm.valueLabel = 'P2P_VALUE';
        } else {
            vm.valueLabel = 'VALUE';
        }

        vm.data = requestDataFactory.createRequest(
            {
                options: []
            }
        );

        vm.jobGroupRequired = jobGroupRequired;
        vm.jobGroupCollectionResponse = jobGroupCollectionResponse;
        vm.statutoryTypeCollectionResponse = statutoryTypeCollectionResponse;
        vm.serviceProviderResponse = serviceProviderResponse;
        vm.partnershipResponse = partnershipResponse;
        vm.priorityCollectionResponse = priorityService.addExtraPriorities(priorityCollectionResponse);

        vm.serviceProviderNotSet = true;
        vm.p2pSupplierList = [];
        vm.selectedJobRequisites = [];

        vm.locationCollectionResponse = locationCollectionResponse;
        vm.locationRequired = siteResponse.locationRequired;
        vm.location = lodash.get(quoteRequestResponse, '_links.location');
        vm.requireDocumentTypeWhenRaisingReactiveJob = settingCollectionResponse.requireDocumentTypeWhenRaisingReactiveJob;
        vm.siteRequiredDocumentTypeRequired = siteResponse.excludeFromRequiringDocumentType;

        if (vm.location) {
            lodash.set(vm.data, '_links.location.href', quoteRequestResponse.getLink('location'));
        }

        angular.forEach(vm.requiredDocumentType.documentTypes, function (value) {
            if (value.name === 'Certificate') {
                vm.certificateRequiredDocType = value;
            }
        });

        for (var i = 0; i < vm.quoteOptionCollectionResponse.count; i++) {
            vm.selectedJobRequisites[i] = [];
            jobRequisiteCollectionResponse.jobRequisites.forEach(function (jr) {
                if (jr.quotedWorksOption === 3) { //opt_out
                    vm.selectedJobRequisites[i].push(jr);
                }
            });
        }

        // user permission manage service providers
        vm.serviceProviderNotSet = !vm.serviceProviderResponse.p2pReference;

        vm.submit = submitAction;
        vm.isPriorityValid = isPriorityValid;
        vm.toggleForm = toggleForm;
        vm.hideForm = hideForm;
        vm.canSubmit = canSubmit;
        vm.unsetStatutory = unsetStatutory;
        vm.changeOperative = changeOperative;
        vm.p2pSearchSupplier = p2pSearchSupplier;
        vm.addNominalLine = addNominalLine;
        vm.removeNominalLine = removeNominalLine;
        vm.changeJobGroup = changeJobGroup;
        vm.removeJobRequisite = removeJobRequisite;
        vm.changeSiteAssociateUser = changeSiteAssociateUser;
        vm.toggleSecondaryApproval = toggleSecondaryApproval;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.removeDocumentType = removeDocumentType;
        vm.checkAttendanceDate = checkAttendanceDate;
        vm.checkCompletionDate = checkCompletionDate;

        var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};

        var jobGroupModel = {
            response: vm.jobGroupCollectionResponse,
            link: vm.jobGroupCollectionResponse.getLink('self'),
            required: vm.jobGroupRequired,
            responseKeyPath: 'jobgroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeJobGroup,
            onRemove: changeJobGroup,
            isHierarchical: true,
            index: 0
        };

        var priorityModel = {
            response: priorityCollectionResponse,
            link: priorityCollectionResponse.getLink('self'),
            linkParameters: priorityLinkParameters,
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        var serviceProviderModel = {
            response: null,
            link: null,
            selected: {object: {value:serviceProviderResponse.name, name:serviceProviderResponse.name}, value:serviceProviderResponse.name},
            responseKeyPath: 'serviceProviders',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.name',
            disabled: true,
            populateDisabled: true,
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder
        };

        var operativeModel = {
            response: operativeResponse,
            link: null,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.title',
            onSelect: changeOperative,
            onRemove: changeOperative,
            includeEmbeddedData: 'serviceTypes',
            linkParameters: { permission: 'operative_permission_receive_jobs', includeServiceType: true }
        };

        // P2P models
        if (vm.isValidP2PUser) {
            vm.selectedP2PSupplierModel = {
                response: null,
                link: null,
                required: true,
                responseKeyPath: 'suppliers',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'Title',
                refreshOnLinkChange: false,
                onSelect: changeSupplier,
                onRemove: changeSupplier
            };
        }

        var jobRequisiteModel = {
            response: jobRequisiteCollectionResponse,
            linkParameters: {active:1},
            required: false,
            responseKeyPath: 'jobRequisites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeJobRequisite,
            onRemove: changeJobRequisite
        };

        var siteAssociateUsersModel = {
            response: null,
            required: true,
            linkParameters: {valueApproval: true},
            responseKeyPath: 'associates',
            itemHrefPath: '_links.user.href',
            itemValuePath: '_links.user.title',
            searchKey: 'siteAssociateName',
            onSelect: changeSiteAssociateUser,
            onRemove: changeSiteAssociateUser
        };

        var requiredDocumentTypeModel = {
            response: vm.requiredDocumentType,
            required: false,
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: {status: 'active'},
            onSelect: changeRequiredDocumentType,
            onRemove: changeRequiredDocumentType
        };

        vm.selectedJobGroupModel = [];
        vm.isStatutory = [];
        vm.selectedPriorityModel = [];
        vm.selectedOperativeModel = [];
        vm.selectedServiceProviderModel = [];
        vm.selectedJobRequisiteModel = [];
        vm.selectedRequiredDocumentTypeModel = [];
        vm.selectedSiteAssociateUserModel = null;
        vm.secondaryApprovalRequired = false;

        // p2p
        angular.forEach(quoteOptionCollectionResponse.quoteoptions, function(value, $index) {
            vm.data.options[$index] = {
                _links: {
                    priority: {
                        href: null
                    },
                    statutorytype: {
                        href: null
                    },
                    jobgroup: {
                        href: null
                    },
                    quoteoption: {
                        href: value._links.self.href
                    }
                },
                files: {
                    links: []
                },
                optedInJobRequisites: {
                    links: []
                },
                requiredDocumentTypes: {
                    links: []
                },
                aboveThreshold: value.aboveThreshold,
                accepted: false,
                hideForm: true,
                poNumber: null,
                summary: value.summary,
                description: value.description,
                attendanceDueAt: null,
                completionDueAt: null,
                value: value.value,
                markup: value.markup,
                markedUpValue: value.markedUpValue
            };

            if (vm.isValidP2PUser) {
                vm.data.options[$index].p2pInformation = {
                    CIS: 'No',
                    description: '',
                    comment: '',
                    nominalLines: [],
                    nominalLineData: []
                };

                createNominalLine($index);
            }

            vm.selectedJobGroupModel.push(angular.extend({}, jobGroupModel, {index: $index}));
            vm.selectedPriorityModel.push(angular.extend({}, priorityModel, {index: $index}));
            vm.selectedOperativeModel.push(angular.extend({}, operativeModel, {index: $index}));
            vm.selectedServiceProviderModel.push(angular.extend({}, serviceProviderModel, {index: $index}));
            vm.selectedJobRequisiteModel.push(angular.extend({}, jobRequisiteModel, {index: $index}));
            vm.selectedRequiredDocumentTypeModel.push(angular.extend({}, requiredDocumentTypeModel, {index: $index}));
            vm.selectedRequiredDocumentTypes[$index] = [];

            if (vm.requireDocumentTypeWhenRaisingReactiveJob.value && !vm.siteRequiredDocumentTypeRequired) {
                let requiredDocType = angular.copy(vm.requireDocumentTypeWhenRaisingReactiveJob);
                requiredDocType.index = $index;
                vm.selectedRequiredDocumentTypes[$index].push(requiredDocType);
            }

            vm.isStatutory[$index] = false;
        });

        //If only one option. Default to accepted an open form.
        if (vm.data.options.length == 1) {
            vm.data.options[0].accepted = true;
            vm.data.options[0].hideForm = false;
        }

        // requires USER_MANAGE_SERVICES
        vm.serviceProviderNotSet = !serviceProviderResponse.p2pReference;

        if (vm.isHelpdesk) {
            angular.forEach(siteResponse._embedded.approvers.quoteApprovers.approvers.memberships, function(approver) {
                vm.approvers.push({
                    name: approver._links.user.title,
                    href: approver._links.user.href
                })
            });
            vm.data.approverLink = {approver: {href: vm.approvers[0].href}};
        }

        function changeRequiredDocumentType(documentType, i) {
            var isAlreadySelected = false;
            var index  = documentType !== null ? documentType.index : i;
            if (vm.selectedRequiredDocumentTypeModel[index].selected && vm.selectedRequiredDocumentTypeModel[index].selected.object) {
                angular.forEach(vm.selectedRequiredDocumentTypes[index], function (value) {
                    if (value._links.self.href === vm.selectedRequiredDocumentTypeModel[index].selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedRequiredDocumentTypes[index].push(vm.selectedRequiredDocumentTypeModel[index].selected.object);
                }

                vm.selectedRequiredDocumentTypeModel[index].selected = null;
            } else {
                if (vm.certificateRequiredDocType) {
                    angular.forEach(vm.selectedRequiredDocumentTypes[index], function (value) {
                        if (value.name === 'Certificate') {
                            isAlreadySelected = true;
                        }
                    });
                }

                if (!isAlreadySelected) {
                    vm.selectedRequiredDocumentTypes[index].push(vm.certificateRequiredDocType);
                }
            }
        }

        function removeDocumentType(documentType, index) {
            angular.forEach(vm.selectedRequiredDocumentTypes[index], function (value, i) {
                if (value._links.self.href === documentType._links.self.href) {
                    vm.selectedRequiredDocumentTypes[index].splice(i, 1);
                }
            });
        }

        function createNominalLine(quoteOptionIndex) {
            // store model/response data for accounting elements and tax code
            var nominalLine = {
                accountingElements: angular.copy(accountingElementsResponse.accountingElements),
                taxCode: {
                    quoteOptionIndex: quoteOptionIndex,
                    response: vm.p2pResponse,
                    link: null,
                    required: true,
                    responseKeyPath: 'taxCodes',
                    itemHrefPath: '_links.self.href',
                    itemValuePath: 'Description',
                    onSelect: onSelectTaxCode,
                    onRemove: onRemoveTaxCode,
                }
            };

            // store clean data for submission to API
            vm.data.options[quoteOptionIndex].p2pInformation.nominalLineData.push({
                accountingElements: accountingElementsResponse.accountingElements.map(function (element) {
                    return {
                        type: element.type,
                        value: element.value
                    };
                }),
                ratio: null,
                taxCode: null
            });

            vm.data.options[quoteOptionIndex].p2pInformation.nominalLines.push(nominalLine);

            var nominalLineIndex = vm.data.options[quoteOptionIndex].p2pInformation.nominalLines.length - 1;
            nominalLine.taxCode.nominalLineIndex = nominalLineIndex;

            // request data if the first element(s) already have a value defined (e.g. property reference)
            var previousValue;
            for (var key in nominalLine.accountingElements) {
                if (nominalLine.accountingElements[key].value) {
                    previousValue = nominalLine.accountingElements[key].value;
                } else {
                    requestData(nominalLine.accountingElements[key], nominalLineIndex, quoteOptionIndex).then(function (response) {
                        var nextKey = parseInt(key) + 1;

                        nominalLine.accountingElements[key].model = createAccountingElementModel(
                            response,
                            quoteOptionIndex,
                            nominalLineIndex,
                            key,
                            nominalLine.accountingElements[nextKey] ? nextKey : null // only set nextKey is there is an AccountingElement after this one
                        );
                    });
                    break;
                }
            }
        }

        function toggleSecondaryApproval() {
            if (!vm.secondaryApprovalRequired) {
                if (vm.selectedSiteAssociateUserModel) {
                    vm.selectedSiteAssociateUserModel.selected = null;
                }

                vm.quoteValueErrorMessage = null;
                return;
            }

            if (typeof elbSettings.getSetting('quote_value_approval_site_associate_type').value !== 'number') {
                vm.quoteValueErrorMessage = 'SITE_ASSOCIATE_TYPE_IS_NOT_SET';
                return;
            }

            vm.siteAssociateType = siteAssociateTypesCollectionResponse ? siteAssociateTypesCollectionResponse.siteAssociateTypes[0] : null;

            if (vm.siteAssociateType && vm.secondaryApprovalRequired) {
                vm.siteAssociateType.selected = null;
                var params = {
                    limit: 30,
                    valueApproval: true,
                    siteAssociateType: elbSettings.getSetting('quote_value_approval_site_associate_type').value
                }

                apiClient.get(siteResponse.getLink('associates'), params).then(function (response) {
                    vm.selectedSiteAssociateUserModel = siteAssociateUsersModel;
                    if (response.associates.length === 0) {
                        vm.selectedSiteAssociateUserModel.selected = null;
                        vm.quoteValueErrorMessage = 'QUOTE_USER_NOT_FOUND';
                    } else {
                        vm.selectedSiteAssociateUserModel.selected = response.associates[0]._links.user ? response.associates[0]._links.user : null;
                    }

                    siteAssociateUsersModel.response = response;
                });
                angular.forEach(vm.data.options, function (option) {
                    option.hideForm = true;
                });
            }
        }

        function toggleForm(option) {
            if (vm.secondaryApprovalRequired) {
                return option.hideForm;
            }

            option.hideForm = !option.hideForm;
        }

        function hideForm(option) {
            option.hideForm = true;
        }

        function canSubmit() {
            var accepted = false;
            // if site associate type and site associate user are not selected then you can submit
            // if site associate type is selected and no site associate user you cannot submit
            var allowSubmit = !vm.secondaryApprovalRequired
                || (
                    vm.secondaryApprovalRequired
                    && vm.selectedSiteAssociateUserModel
                    && vm.selectedSiteAssociateUserModel.selected
                );

            angular.forEach(vm.data.options, function(value) {
                if (value.accepted) {
                    accepted = true;
                }
            });

            // if p2p site but not user
            if (!vm.isValidP2PUser && vm.isP2PSite) {
                return false;
            }

            return accepted && allowSubmit && vm.quoteValueErrorMessage === null;
        }

        vm.preSubmit = () => {
            if (vm.form.$invalid && !vm.secondaryApprovalRequired) {
                // if form is invalid - it would show all options with invalid form inputs
                // and would scroll to the first invalid element

                let scrolledToFirstInvalid = false;

                angular.forEach(vm.form.$getControls(), function($control) {
                    if ($control.$invalid) {
                        // display form with invalid options
                        let index = parseInt(/(\d+)$/.exec($control.$name)[0]);
                        vm.data.options.hasOwnProperty(index) && (vm.data.options[index].hideForm = false);

                        // scroll to the first invalid input
                        if (!scrolledToFirstInvalid) {
                            scrolledToFirstInvalid = true;

                            let navbarHeight = jQuery('nav.navbar').height() || 0;
                            let $input = $control.$$element;

                            setTimeout(function() {
                                jQuery('html, body').animate({
                                    scrollTop: $input.offset().top + navbarHeight
                                }, 500);

                                $input.focus();
                            }, 100);
                        }
                    }
                });

                // invalid form - submit canceled
                return false;
            }

            if (!vm.secondaryApprovalRequired) {
                if (vm.selectedSiteAssociateUserModel) {
                    vm.selectedSiteAssociateUserModel.selected = null;
                }
            }

            return canSubmit();
        }

        function submitAction() {
            if (vm.certificateRequiredDocType && vm.quoteResponse.hasOwnProperty('meridianDocType')) {
                let isRequiredDocumentTypeSelected = false;

                for (var i = 0; i < vm.selectedRequiredDocumentTypeModel.length; i++) {
                    if (vm.selectedRequiredDocumentTypes[i].length) {
                        isRequiredDocumentTypeSelected = true;
                        break;
                    }
                }

                let $formElement = jQuery('.required-document-type');

                if (!isRequiredDocumentTypeSelected) {
                    $formElement.addClass('has-error');
                    return;
                } else {
                    $formElement.removeClass('has-error');
                }
            }

            var data = angular.copy(vm.data),
                ratiosInvalid = false;

            var error = false;

            if (vm.secondaryApprovalRequired && data._links.location) {
                delete data._links.location;
            }

            angular.forEach(data.options, function (value, $index) {
                if (value.completionDueAt && value.attendanceDueAt) {
                    if (value.completionDueAt < value.attendanceDueAt) {
                        messenger.error('COMPLETION_BEFORE_ATTENDANCE');
                        error = true;
                    }

                    if (value.attendanceDueAt < new Date()) {
                        messenger.error('ATTENDANCE_DATE_CANNOT_BE_IN_PAST');
                        error = true;
                    }

                    if (value.completionDueAt < new Date()) {
                        messenger.error('COMPLETION_DATE_CANNOT_BE_IN_PAST');
                        error = true;
                    }
                }


                if (value.p2pInformation && value.accepted) {
                    var floatUsed = false;

                    var ratios = value.p2pInformation.nominalLineData.reduce(function (total, line) {
                        if (line.ratio !== parseInt(line.ratio)) {
                            floatUsed = true;
                        }

                        return total + line.ratio;
                    }, 0);

                    if (ratios !== 100 || floatUsed) {
                        vm.data.options[$index].p2pInformation.ratiosInvalid = true;
                        ratiosInvalid = true;
                    } else {
                        vm.data.options[$index].p2pInformation.ratiosInvalid = false;
                    }
                }

                angular.forEach(value._links, function (resource, key) {
                    if (resource.href === null) {
                        delete data.options[$index]._links[key];
                    }
                });

                angular.forEach(vm.selectedRequiredDocumentTypes[$index], function(value) {
                    data.options[$index].requiredDocumentTypes.links.push({
                        href: value._links.self.href
                    });
                });

                if (data.options[$index].p2pInformation) {
                    delete data.options[$index].p2pInformation.nominalLines;
                }

                angular.forEach(vm.selectedJobRequisites[$index], function(jobRequisite) {
                    data.options[$index].optedInJobRequisites.links.push({href: jobRequisite.getLink('self')});
                });
            });

            if (!error) {
                if (ratiosInvalid) {
                    messenger.error('P2P_ERROR_RATIOS_INVALID');
                    return;
                }

                // workflow quote approval escalation
                if (vm.selectedSiteAssociateUserModel && vm.selectedSiteAssociateUserModel.selected) {
                    data.siteAssociateType = vm.siteAssociateType._links.self.href;
                    data._links.siteAssociateType = { href: vm.siteAssociateType._links.self.href };
                    data._links.valueApprover = { href: vm.selectedSiteAssociateUserModel.selected.href };

                    apiClient.create(quoteResponse.getLink('request-value-approval'), data).then(function (response) {
                        if (response) {
                            messenger.success('QUOTE_VALUES_APPROVAL_REQUESTED');
                            $state.go('^', {}, {reload: $state.get('^.^.^.^')});
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                    return;
                }

                apiClient.create(quoteResponse.getLink('accept'), data).then(function (response) {
                    if (response) {
                        messenger.success('QUOTE_ACCEPTED');
                        $state.go('^', {}, {reload: $state.get('^.^.^.^')});
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }

        function p2pSearchSupplier() {
            p2pService.updateSelectedSupplierModel(vm.selectedP2PSupplierModel, vm.p2pSupplierSearchModel, quoteResponse);
        }

        // single instance
        function changeSupplier(supplierModel) {
            vm.data.p2pSupplier = supplierModel.selected.object.Code;
        }

        function changeJobGroup(jobGroupModel) {
            if (jobGroupModel && jobGroupModel.selected && jobGroupModel.selected.object) {
                vm.data.options[jobGroupModel.index]._links.jobgroup = { href: jobGroupModel.selected.href };
            } else {
                vm.data.options[jobGroupModel.index]._links.jobgroup = null;
            }
        }

        function changePriority (priorityModel) {

            vm.data.options[priorityModel.index]._links.priority.href = null;

            if (priorityModel && priorityModel.selected && priorityModel.selected.object) {

                vm.data.options[priorityModel.index].isByPlannedDate = (priorityModel.selected.value === $translate.instant('BY_PLANNED_DATE'));
                vm.data.options[priorityModel.index].isOnNextPlannedVisit = priorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                if (vm.data.options[priorityModel.index].isOnNextPlannedVisit || vm.data.options[priorityModel.index].isByPlannedDate) {
                }

                if (!vm.data.options[priorityModel.index].isByPlannedDate && !vm.data.options[priorityModel.index].isOnNextPlannedVisit) {
                    vm.data.options[priorityModel.index].attendanceDueAt = null;
                    vm.data.options[priorityModel.index].completionDueAt = null;
                    vm.data.options[priorityModel.index]._links.priority.href = priorityModel.selected.href;
                }
            } else {
                vm.data.options[priorityModel.index].isByPlannedDate = vm.data.options[priorityModel.index].isOnNextPlannedVisit = false;
                vm.data.options[priorityModel.index].attendanceDueAt = vm.data.options[priorityModel.index].completionDueAt = null;
            }
        }

        function isPriorityValid($index) {
            var option = vm.data.options[$index];

            if (!lodash.has(option, '_links.priority.href') || option._links.priority.href === null) {
                if (option.isOnNextPlannedVisit === true) {
                    return true;
                }

                return ! (option.attendanceDueAt === null || option.completionDueAt === null);
            }

            return true;
        }

        function unsetStatutory(index) {
            delete vm.data.options[index]._links.statutorytype;
            if (vm.certificateRequiredDocType) {
                removeDocumentType(vm.certificateRequiredDocType,index);
            }
        }

        function changeOperative(operativeModel) {
            if (operativeModel.selected && operativeModel.selected.object) {
                vm.data.options[operativeModel.index]._links.operative = {href: operativeModel.selected.href};
            } else {
                delete vm.data.options[operativeModel.index]._links.operative;
            }
        }

        function onSelectTaxCode(code) {
            vm.data.options[code.quoteOptionIndex].p2pInformation.nominalLineData
                [code.nominalLineIndex]
                .taxCode = code.selected.object.Code;
        }

        function onRemoveTaxCode(code) {
            vm.data.options[code.quoteOptionIndex].p2pInformation.nominalLineData
                [code.nominalLineIndex]
                .taxCode = null;
        }

        function createAccountingElementModel(response, quoteOptionIndex, nominalLineIndex, accountingElementKey, nextKey) {
            return {
                quoteOptionIndex: quoteOptionIndex,
                nominalLineIndex: nominalLineIndex,
                accountingElementKey: accountingElementKey,
                nextKey: nextKey,
                response: response,
                link: null,
                required: true,
                refreshOnLinkChange: false,
                responseKeyPath: 'data',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'Name',
                onSelect: onSelectAccountingElement,
                onRemove: onRemoveAccountingElement
            };
        }

        function requestData(accountingElement, nominalLineIndex, quoteOptionIndex) {
            var data = {};
            if (typeof nominalLineIndex !== 'undefined') {
                data = { accountingElements: JSON.stringify(vm.data.options[quoteOptionIndex].p2pInformation.nominalLineData[nominalLineIndex].accountingElements) };
            }

            return apiClient.get(p2pResponse.getLink('self') + '/' + accountingElement.type, data);
        }

        function onSelectAccountingElement(element) {
            vm.data.options
                [element.quoteOptionIndex]
                .p2pInformation
                .nominalLineData
                [element.nominalLineIndex]
                .accountingElements
                [element.accountingElementKey]
                .value = element.selected.object.ID;

            if (element.nextKey) {
                requestData(
                    accountingElementsResponse.accountingElements[element.nextKey],
                    element.nominalLineIndex,
                    element.quoteOptionIndex
                ).then(function (response) {
                    var nextKey = parseInt(element.nextKey) + 1;

                    vm.data.options
                        [element.quoteOptionIndex]
                        .p2pInformation
                        .nominalLines
                        [element.nominalLineIndex]
                        .accountingElements
                        [element.nextKey]
                        .model = createAccountingElementModel(
                        response,
                        element.quoteOptionIndex,
                        element.nominalLineIndex,
                        element.nextKey,
                        vm.data.options[element.quoteOptionIndex].p2pInformation.nominalLines[element.nominalLineIndex].accountingElements[nextKey] ? nextKey : null
                    );
                });
            }
        }

        function onRemoveAccountingElement(element) {
            var accountingElementCount = vm.data.options[element.quoteOptionIndex].p2pInformation.nominalLines[element.nominalLineIndex].accountingElements.length;
            var startKey = parseInt(element.accountingElementKey) + 1;

            // clear out all AccountingElements after the one that was cleared
            for (var i = startKey; i < accountingElementCount; i++) {
                vm.data.options[element.quoteOptionIndex].p2pInformation.nominalLines[element.nominalLineIndex].accountingElements[i].model = null;
                vm.data.options[element.quoteOptionIndex].p2pInformation.nominalLineData[element.nominalLineIndex].accountingElements[i].value = null;
            }
        }

        function addNominalLine(quoteOptionIndex) {
            createNominalLine(quoteOptionIndex);
        }

        function removeNominalLine(quoteOptionIndex, nominalLineIndex) {
            vm.data.options[quoteOptionIndex].p2pInformation.nominalLines.splice(nominalLineIndex, 1);
            vm.data.options[quoteOptionIndex].p2pInformation.nominalLineData.splice(nominalLineIndex, 1);
        }

        function getIfLengthRequired() {
            if (vm.p2pResponse.isNewFormat) {
                return vm.maxCharacterCount;
            } else {
                return false;
            }
        }

        function changeJobRequisite(jobRequisite) {
            if (vm.selectedJobRequisiteModel[jobRequisite.index].selected && vm.selectedJobRequisiteModel[jobRequisite.index].selected.object) {
                var isAlreadySelected = false;

                angular.forEach(vm.selectedJobRequisites[jobRequisite.index], function(value, index) {
                    if (value.getLink('self') === vm.selectedJobRequisiteModel[jobRequisite.index].selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedJobRequisites[jobRequisite.index].push(jobRequisite.selected.object);
                }

                vm.selectedJobRequisiteModel[jobRequisite.index].selected = null;
            }
        }

        function removeJobRequisite(jobRequisite, $index) {
            angular.forEach(vm.selectedJobRequisites[$index], function(value, index) {
                if (value.getLink('self') === jobRequisite.getLink('self')) {
                    vm.selectedJobRequisites[$index].splice(index, 1);
                }
            });
        }

        function changeSiteAssociateUser(siteAssociateUser) {
            if (siteAssociateUser.selected) {
                vm.selectedSiteAssociateUserModel = siteAssociateUser;
            } else {
                vm.selectedSiteAssociateUserModel.selected = null;
                canSubmit();
            }
        }

        if (vm.certificateRequiredDocType && vm.quoteResponse.hasOwnProperty('meridianDocType')) {
            for (var i = 0; i < vm.selectedRequiredDocumentTypeModel.length; i++) {
                vm.selectedRequiredDocumentTypes[i].push(vm.certificateRequiredDocType);
            }
        }

        function checkAttendanceDate(date, index) {
            if (vm.data.options[index].isOnNextPlannedVisit) {
                return false;
            }

            if (!date) {
                return true;
            }

            if (
                vm.data.options[index].isByPlannedDate &&
                date &&
                date < new Date() ||
                (vm.data.options[index].completionDueAt && date > vm.data.options[index].completionDueAt)
            ) {
                return true;
            }

            return false;
        }

        function checkCompletionDate(date, index) {
            if (vm.data.options[index].isOnNextPlannedVisit) {
                return false;
            }

            if (!date) {
                return true;
            }

            if (
                date &&
                date < new Date() ||
                (vm.data.options[index].attendanceDueAt && date < vm.data.options[index].attendanceDueAt)
            ) {
                return true;
            }

            return false;
        }
    }
})();
