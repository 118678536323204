angular.module('elogbooksServices').service('paramsService', function($stateParams, $state, $rootScope) {
    var self = this;

    // Emulate $stateParams behaviour and copy toParams properties to this service, so...
    // $stateParams.someParam === paramsService.someParam
    // Add global filter bar parameters if set
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        var routes = ['dashboard.admin', 'dashboard.user.reports'];

        if (routeContains(routes, toState.name) || typeof toState.parent === 'undefined') {
            return;
        }

        if (getFromLocalStorage('siteFilter')) {
            toParams = checkMappings(toState.parent, toParams, 'site');
        }

        if (getFromLocalStorage('regionFilter') || (fromParams.siteRegions && fromParams.siteRegions.length > 0)) {
            toParams = checkMappings(toState.parent, toParams, 'region');
        }

        if (getFromLocalStorage('siteAssociateTypeFilter')) {
            toParams = checkMappings(toState.parent, toParams, 'siteAssociateType');
        }

        if (getFromLocalStorage('userIdFilter')) {
            toParams = checkMappings(toState.parent, toParams, 'userId');
        }

        angular.copy(toParams, self);
    });

    //do the correct param mapping depending on state
    function checkMappings(state, params, filter) {
        if (state.indexOf('dashboard.user.sites.list.details') === -1) {
            switch(state) {
                case 'dashboard.user.sites':
                case 'dashboard.user.service-providers.list.details':
                    var mapping = {
                        'region': 'siteRegions'
                    };

                    if (getFromLocalStorage(filter + 'Filter')) {
                        params[mapping[filter]] = [getFromLocalStorage(filter + 'Filter')];
                    } else {
                        params[mapping[filter]] = null;
                    }

                    params[filter] = null;

                    break;
                case 'dashboard.user.jobs':
                    var mapping2 = {
                        'region': 'siteRegionId'
                    };

                    if (getFromLocalStorage(filter + 'Filter')) {
                        params = remapValues(mapping2, params, filter);
                    } else {
                        params[mapping2[filter]] = null;
                    }
                    break;
                case 'dashboard.user.tasks':
                case 'dashboard.user.tasks.manage-tasks':
                case 'dashboard.user.tasks.generate-planner':
                    var mapping3 = {
                        'region': 'regionId'
                    };

                    if (getFromLocalStorage(filter + 'Filter') && getFromLocalStorage(filter + 'FilterName')) {
                        params[mapping3[filter]] = JSON.stringify({v: getFromLocalStorage(filter + 'Filter'), n: getFromLocalStorage(filter + 'FilterName')});
                    } else {
                        params[mapping3[filter]] = null;
                    }

                    break;
                default:
                    params[filter] = getFromLocalStorage(filter + 'Filter');
            }
        } else {
            params['region'] = null;
            params['siteAssociateType'] = null;
            params['userId'] = null;
            params['site'] = null;
        }

        return params;
    }

    function routeContains(routes, route) {
        for (var i=0; i < routes.length; i++) {
            if (route.indexOf(routes[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    function getFromLocalStorage(filter) {
        return window.localStorage.getItem(filter) !== 'null' ? window.localStorage.getItem(filter) : null;
    }

    function remapValues(mappings, params, filter) {
        if (mappings[filter] !== undefined) {
            params[mappings[filter]] = getFromLocalStorage(filter + 'Filter');

            delete params[filter];
        } else {
            params[filter] = getFromLocalStorage(filter + 'Filter');
        }

        return params;
    }

    return this;
});
