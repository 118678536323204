(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedSiteFiltersModalController', advancedSiteFiltersModalController);

    advancedSiteFiltersModalController.$inject = ['$state', '$stateParams', 'config', 'siteGroupCollectionResponse', 'regionCollectionResponse', 'sectorCollectionResponse',
        'associateTypeCollectionResponse', 'siteAssociateCollectionResponse', '$uibModalInstance', '$rootScope', '$scope', '$translate', 'lodash', 'moment', 'paramConverterService',
        '$filter', 'apiClient', 'user', 'approversCollectionResponse', 'coreClientsCollectionResponse'];

    function advancedSiteFiltersModalController($state, $stateParams, config, siteGroupCollectionResponse, regionCollectionResponse, sectorCollectionResponse,
             associateTypeCollectionResponse, siteAssociateCollectionResponse, $uibModalInstance, $rootScope, $scope, $translate, lodash, moment, paramConverterService,
             $filter, apiClient, user, approversCollectionResponse, coreClientsCollectionResponse) {

        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.getSiteGroups = getSiteGroups;
        vm.getRegions = getRegions;
        vm.getAssociateTypes = getAssociateTypes;
        vm.getAssociates = getAssociates;
        vm.getSectors = getSectors;
        vm.saveParams = saveParams;
        vm.clear = clear;
        vm.siteAssociateCollectionResponse = siteAssociateCollectionResponse;
        vm.getAssociatesByType = getAssociatesByType;
        vm.associateSelect = {};
        vm.associateTypeSelect = {};
        var selectDisabled = false;
        var params = {};

        vm.data = {};
        vm.advancedCriteria = {};

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        function getAssociateTypes() {
            return paramConverterService.formatResponse(associateTypeCollectionResponse.siteAssociateTypes);
        }

        function getSiteGroups() {
            return paramConverterService.formatResponse(siteGroupCollectionResponse.sitegroups);
        }

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        function getAssociates() {
            return paramConverterService.formatResponse(siteAssociateCollectionResponse.users);
        }

        function getSectors() {
            return paramConverterService.formatResponse(sectorCollectionResponse.sectors);
        }

        if ($stateParams.siteAssociateType) {
            getAssociatesByType(JSON.parse($stateParams.siteAssociateType))
        }

        vm.advancedCriteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null },
            externalReference: { type: 'text', title: 'EXTERNAL_REFERENCE', value: $stateParams.externalReference },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status, options: statusOptions },
            siteName: { type: 'text', title: 'NAME', value: $stateParams.siteName },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference },
            address: { type: 'text', title: 'ADDRESS', value: $stateParams.address }
        };

        if (associateTypeCollectionResponse) {
            var selectedAssociatedType = $stateParams.siteAssociateType ? {
                title: JSON.parse($stateParams.siteAssociateType).n,
                value: JSON.parse($stateParams.siteAssociateType).v
            } : null;

            vm.associateTypeSelect = {
                response : associateTypeCollectionResponse,
                link : associateTypeCollectionResponse ? associateTypeCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'siteAssociateTypes',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                selected: selectedAssociatedType
            };

            vm.advancedCriteria.siteAssociateType = { type: 'jsonselectwidget', title: 'SITE_ASSOCIATE_TYPES', value: JSON.parse($stateParams.siteAssociateType) || null, options: vm.associateTypeSelect };
        }

        if (!!approversCollectionResponse?.users?.length) {
            var selectedApprover = $stateParams.approver ? {
                title: JSON.parse($stateParams.approver).n,
                value: JSON.parse($stateParams.approver).v
            } : null;

            vm.advancedCriteria.approver = {
                type: 'jsonselectwidget',
                title: 'SITE_APPROVER',
                value: JSON.parse($stateParams.approver),
                options: {
                    mapValue: 'approver',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: approversCollectionResponse,
                    responseKeyPath: 'users',
                    loadmoreEnabled: true,
                    selected: selectedApprover
                }
            };
        }

        if (sectorCollectionResponse) {
            vm.advancedCriteria.sectors = {
                type: 'data_select',
                title: 'SECTORS',
                options: {
                    mapValue: 'sectors',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: sectorCollectionResponse,
                    responseKeyPath: 'sectors',
                    selected: paramConverterService.getSelectedParam(sectorCollectionResponse.sectors, $stateParams.sectors)
                }
            };
        }

        if (coreClientsCollectionResponse?.coreclients) {
            var selectedCoreClient = $stateParams.coreClient ? {
                title: JSON.parse($stateParams.coreClient).n,
                value: JSON.parse($stateParams.coreClient).v
            } : null;

            vm.advancedCriteria.coreClient = {
                type: 'jsonselectwidget',
                title: 'CORE_CLIENT',
                options: {
                    mapValue: 'coreClient',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: coreClientsCollectionResponse,
                    responseKeyPath: 'coreclients',
                    selected: selectedCoreClient
                }
            };
        }

        if (siteGroupCollectionResponse) {
            var selected = $stateParams.siteGroup ? {
                title: JSON.parse($stateParams.siteGroup).n,
                value: JSON.parse($stateParams.siteGroup).v
            } : null;

            vm.advancedCriteria.siteGroup = {
                type: 'jsonselectwidget',
                title: 'SITE_GROUPS',
                value: $stateParams.siteGroup,
                options: {
                    mapValue: 'siteGroup',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: siteGroupCollectionResponse,
                    responseKeyPath: 'sitegroups',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selected
                }
            };
        }

        if (regionCollectionResponse) {
            vm.advancedCriteria.siteRegions = {
                type: 'data_select',
                title: 'REGION',
                options: {
                    mapValue: 'siteRegions',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: regionCollectionResponse,
                    responseKeyPath: 'regions',
                    selected: paramConverterService.getSelectedParam(regionCollectionResponse.regions, $stateParams.siteRegions)
                }
            };
        }

        if ($state.current.name === 'dashboard.admin.sites.list') {
            vm.advancedCriteria.createdAtStart =  { type: 'date', value: $stateParams.createdAtStart, title: 'CREATED_AT_START', maxDependsOn: 'createdAtEnd' };
            vm.advancedCriteria.createdAtEnd =  { type: 'date', value: $stateParams.createdAtEnd, title: 'CREATED_AT_END', minDependsOn: 'createdAtStart' };
        }

        function loadAssociates () {
            var selectedAssociate = $stateParams.associate ? {
                title: JSON.parse($stateParams.associate).n,
                value: JSON.parse($stateParams.associate).v
            } : null;

            vm.associateSelect = {
                response : vm.siteAssociateCollectionResponse,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'users',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                selected: selectedAssociate
            };

            let associate = { type: 'jsonselectwidget', title: 'SITE_ASSOCIATES', value: JSON.parse($stateParams.associate) || null, options: vm.associateSelect };

            //Place associate next to associate type
            const widgetPosition = 7;
            vm.advancedCriteria = Object.keys(vm.advancedCriteria).reduce((ac,a,i) => {
                if(i === widgetPosition) ac["associate"] = associate;
                ac[a] = vm.advancedCriteria[a];
                return ac;
            },{});
        }

        function getAssociatesByType(selectedType) {
            if (selectedType?.v) {
                let associateParams = {
                    siteAssociateType: selectedType.v || null,
                    status: "active",
                    ignoreSiteAccess: "1"
                };

                apiClient.get(user.getLink('users'), associateParams).then(function(response) {
                    vm.siteAssociateCollectionResponse = response;
                    loadAssociates();
                });
            }
        }

        $scope.$watch('vm.advancedCriteria.siteAssociateType', function(newVal, oldVal) {
            if (newVal.jsonValue && newVal.jsonValue?.v !== (oldVal?.jsonValue?.v || oldVal?.value?.v)) {
                delete vm.advancedCriteria.associate;
                $stateParams.associate = params.associate = null;
                getAssociatesByType(newVal.jsonValue);
            } else if (!newVal.value) {
                delete vm.advancedCriteria.associate;
                $stateParams.associate = null;
            }
        }, true);

        function saveParams(selectModel) {
            //mapValue is for data select widget selections
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    params[selectModel.mapValue] = selectModel.selected.object.id;
                }
            } else {
                params = selectModel;
            }

            if (typeof selectModel.response !== 'undefined'
                && selectModel.response.count == 1
                && selectModel.response.selected == null
            ) {
                var url = selectModel.response.getLink('self').replace(/&?id=[0-9]+/g, '');
                selectModel.link = url;

                apiClient.get(url).then(function(response) {
                    selectModel.response = response;
                });
            }
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function clear() {
            var clearValues = {};
            lodash.each($stateParams, function(value, key) {
                if (value) {

                    clearValues[key] = null;
                }
            });

            $state.go('.', angular.extend({}, clearValues), { reload: true });
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                $stateParams.sitesPage = '1';
            }

            var override = {
                page: 1
            };

            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params, override), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
