(function () {
    'use strict';

    angular
        .module('elogbooks.user.assets')
        .controller('CommonImportListController', ['importsCollectionResponse', '$state', '$stateParams', '$translate', CommonImportListController]);

    function CommonImportListController(importsCollectionResponse, $state, $stateParams, $translate) {
        var vm = this;
        vm.importsCollection = importsCollectionResponse;
        vm.imports = importsCollectionResponse.imports;
        vm.entityName = $translate.instant('IMPORTS');
        vm.importRoute = '.add';
        vm.isCurrentState = $state.current.name.indexOf('dashboard.admin.imports') > -1;
        vm.noBorder = false;
        const { status, type, debug, startDate, endDate, filename } = $stateParams;


        const defaultStatus = ['0', '1', '2', '3', '4', '5', '6'];

        if (type === null) {
            vm.noType = true;
        } else if (!vm.isCurrentState) {
            vm.noBorder = true;
        }

        if (typeof status === 'undefined') {
            $stateParams.status = defaultStatus;
        } 

        // Hateoas serializes templates response as an object rather than array if there's only one object in the 'collection'.
        var imports = (typeof importsCollectionResponse._links.templates.length === 'undefined') ? [importsCollectionResponse._links.templates] : importsCollectionResponse._links.templates;

        var typeOptions = [
            {
                title: $translate.instant('ALL'),
                value: 'all'
            }
        ];

        var debugOptions = [
            { title: $translate.instant('ALL'), value: 'all' },
            { title: $translate.instant('YES'), value: '1' },
            { title: $translate.instant('NO'), value: '0' }
        ]

        var statusOptions = [
            { title: $translate.instant('STATUS_PENDING'), value: '0' },
            { title: $translate.instant('STATUS_IN_PROGRESS'), value: '1' },
            { title: $translate.instant('STATUS_DONE'), value: '2' },
            { title: $translate.instant('STATUS_DONE_WITH_ERRORS'), value: '3' },
            { title: $translate.instant('STATUS_INVALID_FILE'), value: '4' },
            { title: $translate.instant('STATUS_INVALID_TYPE'), value: '5' }
        ]        

        angular.forEach(imports, function(importEntity) {
            typeOptions.push({
                title: $translate.instant(importEntity.name.toUpperCase()),
                value: importEntity.name
            });
        });

        vm.search = searchAction;
        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: type, options: typeOptions, clearValue: 'all' },
            status: { type: 'options', multiple: true, title: 'STATUS', value: status, options: statusOptions, clearValue: defaultStatus },
            startDate: { type: 'date', value: startDate, title: 'DATE_STARTED_AT',  maxDependsOn: 'endDate' },
            endDate: { type: 'date', value: endDate, title: 'DATE_ENDED_AT', minDependsOn: 'startDate' },
            filename: { type: 'text', title: 'FILE_NAME', value: filename },
            debug: { type: 'options', title: 'DEBUG', value: debug, options: debugOptions, clearValue: 'all' }
        };

        function searchAction (params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
