app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        USER: 'User',
        USERS: 'Users',

        USER_EDIT: 'Edit User',
        USER_LIST: 'Users',
        USER_ADD: 'Add a new User',
        ADD_NEW_USER: 'Add a User',
        NEW_SITE_ACCESS: 'Grant Access to new Sites',
        USER_CREATED: 'User Created',
        NEW_ROW: 'New row',
        USER_UPDATED: 'User Updated',
        ADD_SPECIFIC_SITE_ACCESS: 'Add Specific Site Access',

        USER_ROLES: 'Roles',
        ALL_ROLES: 'All roles',
        SELECTED_ROLES: 'Selected roles',
        CHANGE_PASSWORD: 'Change password',
        PASSWORD_RESET_REQUEST_SUCCESSFUL: 'Please check your mail for password change instructions.',
        USER_PREFERENCES_VIEW: 'User Preferences',
        USER_PREFERENCES_EDIT: 'Edit user preferences',
        USER_PREFERENCES: 'User Preferences',
        USER_NOTIFICATION_TYPE: 'Notification method',
        NOTIFICATIONMETHOD: 'Notification method',
        EMAILCONTENTTYPE: 'Email content type',
        EMAIL_CONTENT_TYPE: 'Email content type',
        USER_EMAIL_CONTENT_TYPE: 'Email content type',
        USER_PREFERENCE_ALL: 'all',
        USER_PREFERENCE_NOTIFICATION: 'notification',
        USER_PREFERENCE_EMAIL: 'email',
        USER_PREFERENCE_TEXT: 'text',
        USER_PREFERENCE_HTML: 'html',
        PREFERENCES_UPDATED: 'Preferences have been updated',
        USER_PREFERENCE_LOCALE: 'Locale',
        USER_PREFERENCE_NOTIFY_ON_CREATE_JOB: 'Notify on Create Job',
        USER_PREFERENCE_NOTIFY_ON_COMPLETE_PLANNED_JOB: 'Notify on Complete Planned Job',
        USER_PREFERENCE_NOTIFY_ON_COMPLETE_REACTIVE_JOB: 'Notify on Complete Reactive Job',
        USER_PREFERENCE_NOTIFY_ON_COMPLETE_STATUTORY_JOB: 'Notify on Complete Statutory Job',
        USER_PREFERENCE_NOTIFY_ON_COMPLETE_DOC_TYPE_REQUIRE_APPROVAL_JOB: 'Notify when Job Complete with doc type requiring approval',
        USER_PREFERENCE_NOTIFY_ON_CREATE_QUOTE: 'Notify on Create Quote',
        USER_PREFERENCE_NOTIFY_ON_ACCEPT_QUOTE: 'Notify on Accept Quote',
        USER_PREFERENCE_NOTIFY_ON_PAPERWORK_FOR_REVIEW_ACTION_CREATED: 'Notify on Paperwork for review Action creation',
        WEEKLY_DASHBOARD_REPORT: 'Weekly Dashboard Report',

        THIS_USER_HAS_NO_SITES: 'This user have no access to sites',
        GIVE_ACCESS: 'Give Access',
        CHANGE_SITE_ACCESS: 'Change Site Access',
        GRANT_ACCESS: 'Grant Access',
        REVOKE_ACCESS: 'Revoke Access',
        TO_ALL_ACTIVE_SITES: 'To all active sites',
        SELECT_SPECIFIC_SITES: 'Select specific sites',
        COPY_FROM_USER: 'Copy from user',
        FROM_ALL_SITES_ON_THE_SYSTEM: 'From all sites on the system',
        CONFIRM_AND_FINISH: 'Confirm and finish',
        CONTINUE_AND_CHOOSE_SITES: 'Continue and choose sites',
        AVAILABLE_SITES: 'Available Sites',
        GRANT_ACCESS_TO: 'Grant access Sites',
        MODIFY: 'Modify',

        SITE_PERMISSIONS: 'Site Permissions',
        SELECT_PERMISSIONS: 'Select Permissions',
        ADD_PERMISSIONS: 'Add Permissions',
        UPDATE_PERMISSIONS: 'Update Permissions',
        EXPLICIT_PERMISSIONS: 'Explicit Permissions',
        USER_HAS_NO_SITE_ACCESS: 'User has no site access',
        USER_PERMISSIONS: 'User Permissions',
        PERMISSIONS: 'Permissions',
        PERMISSION: 'Permission',
        REMOVE: 'Remove',

        NO_OPERATIVES_ASSIGNED: 'No Operatives Assigned',

        SITE_PERMISSIONS_UPDATED: 'Site Permissions Updated',
        USER_PERMISSIONS_UPDATED: 'User Permissions Updated',
        USER_TOKENS: 'Tokens',
        TOKEN: 'Token',
        TOKENS_LIST: 'Tokens List',
        EXPIRATION_DATE: 'Expiration Date',
        TOKENS_ADD: 'Create a New Token',
        USER_TOKEN_ADD: 'Create Token',
        TOKENS: 'Tokens',
        TOKEN_ADD: 'Create a New Token',
        TOKEN_CREATED: 'Token Created',
        TOKEN_REVOKED: 'Token Revoked',
        STANDARD: 'Standard',
        MERIDIAN: 'Meridian',
        RISKWISE: 'RiskWise',
        ALCUMUS: 'Ligtas',
        PROPERTYPLUS: 'Property Plus',
        PROPERTYPLUS_LIGTAS: 'Property Plus (Ligtas)',
        QCOMPLIANCE: 'Q-Compliance',
        USED_TO_SET_API_USER_TYPE: 'Used to set API User type',
        INVALID_USER_TYPE: 'Invalid User Type. You may be attempting to use a user type for a disabled module.',
        USER_TYPE: 'Type',
        INVALID_TOKEN: 'Invalid Token',
        INVALID_TOKEN_NO_SUBMIT: 'Invalid token, unable to submit',
        INVALID_TOKEN_NO_SUBMIT_FEEDBACK: 'Invalid token - Unable to submit feedback',
        ROLES_TO_MANY_MEMBERSHIPS: 'A user has more than 100 site memberships, so please only add or remove 1 role per request.',
        SITE_ACCESS_REMOVED: 'Site Access Removed',
        CONTACT_HAS_NO_EMAIL: 'Users must have at least 1 email address, please add an email address',
        EMAIL_SENT_TO: 'Email sent to',
        EMAIL_NOT_VALID: 'Email address is not valid',
        PHONE_NUMBER_NOT_VALID: 'Telephone number is not valid',

        USER_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated the user.',
        USER_EDITED_SYSTEM_ACTION: 'The system automatically updated the user.',
        USER_INACTIVE_NOTIFIED_SYSTEM_ACTION: '{{ ::event.metadata.note }}<strong>{{ ::event.metadata.userEmailAddress }}</strong>',
        USER_RESET_PASSWORD_EMAIL_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent password reset email.',
        USERNAME_REMINDER_EMAIL_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent a Username reminder email.',
        USER_INACTIVATED_SYSTEM_ACTION: 'User has been deactivated.',

        ASSOCIATE_DETAILS: 'Associate details',
        DEPARTMENT: 'Department',

        USER_INTEGRATIONS: 'Service Provider Integraitons',
        EMAIL_UPDATE_NOT_ALLOWED: 'Email update not allowed',

        USER_CATEGORY: 'Category',
        USER_CATEGORY_ELOGS: 'Elogs',
        USER_CATEGORY_CLIENT: 'Client',
        USER_CATEGORY_SERVICE_PROVIDER: 'Service Provider',
        USER_CATEGORY_API_USER: 'API User',
        USER_CATEGORY_OTHER: 'Other',
        USER_CATEGORY_SUBCONTRACTOR: 'Subcontractor'
    });
});
