(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {

            QUOTE: 'Quote',
            QUOTES: 'Quotes',

            QUOTE_WITH_ID: 'Quote {{ id }}',

            QUOTE_LIST: 'Quotes',
            QUOTE_VIEW: 'Quote',

            QUOTE_SUMMARY: 'Quote Summary',
            OPTION_SUMMARY: 'Option Summary',
            QUOTE_DESCRIPTION: 'Quote Description',
            QUOTE_PRIORITY: 'Quote Priority',

            QUOTE_ADD: 'Add a new Quote',
            QUOTE_CREATED: 'Quote Created',
            QUOTE_UPDATED: 'Quote Updated',
            QUOTE_DELETED: 'Quote Deleted',

            QUOTE_ID: 'Quote {{ id }}',
            ID_QUOTE: 'Quote ID',
            QUOTE_DETAILS: 'Quote Details',
            QUOTE_STATUS: 'Status',
            QUOTE_CONTACTS: 'Quote Contacts',

            QUOTE_AUDIT_TRAIL_SENT: 'Audit Trail Sent',

            QUOTE_CONTACT: 'Quote Contact',

            QUOTE_CONTACT_LIST: 'Quote Contacts',
            QUOTE_CONTACT_ADD: 'Add',

            QUOTE_CONTACT_CREATED: 'Quote Contact Created',
            QUOTE_CONTACT_UPDATED: 'Quote Contact Updated',
            QUOTE_CONTACT_DELETED: 'Quote Contact Deleted',

            QUOTE_WORKFLOW_ABSTAIN: 'Decline to Quote',
            QUOTE_WORKFLOW_ACCEPT: 'Accept',
            QUOTE_WORKFLOW_CANCEL: 'Cancel',
            QUOTE_WORKFLOW_HOLD: 'Hold',
            QUOTE_WORKFLOW_HOLD_SUCCESS: 'Quote Put on Hold',
            QUOTE_WORKFLOW_REJECT: 'Reject',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION: 'Request More Information',
            QUOTE_WORKFLOW_SUBMIT: 'Submit',
            QUOTE_WORKFLOW_BUDGET_QUOTE: 'Submit',
            QUOTE_WORKFLOW_REOPEN: 'Reopen',
            QUOTE_WORKFLOW_ESCALATE: 'Escalate',
            QUOTE_WORKFLOW_REQUEST_FULL_QUOTE: 'Request Full Quote',

            QUOTE_WORKFLOW_ABSTAIN_SUBMIT: 'Decline',
            QUOTE_WORKFLOW_ABSTAIN_SUCCESS: 'Quote Declined',
            QUOTE_WORKFLOW_ACCEPT_SUBMIT: 'Accept Quote',
            QUOTE_WORKFLOW_ACCEPT_VALUES_SUBMIT: 'Accept Values',
            QUOTE_WORKFLOW_CANCEL_SUBMIT: 'Cancel Quote',
            QUOTE_WORKFLOW_HOLD_SUBMIT: 'Hold',
            QUOTE_WORKFLOW_REJECT_SUBMIT: 'Reject Quote',
            QUOTE_WORKFLOW_REJECT_VALUES_SUBMIT: 'Reject Quote Values',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUBMIT: 'Request More Information',
            QUOTE_WORKFLOW_SUBMIT_SUBMIT: 'Submit Quote',
            QUOTE_WORKFLOW_BUDGET_QUOTE_SUBMIT: 'Submit Budget Quote',
            QUOTE_WORKFLOW_REOPEN_SUBMIT: 'Reopen',
            QUOTE_WORKFLOW_ESCALATE_SUBMIT: 'Escalate',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escalate',
            DOES_QUOTE_REQUIRE_SECONDARY_APPROVAL: 'Does this quote require secondary value approval?',

            QUOTE_WORKFLOW_SUBMIT_SUCCESS: 'Quote Submitted',
            QUOTE_WORKFLOW_BUDGET_QUOTE_SUCCESS: 'Budget Quote Submitted',

            QUOTE_WORKFLOW_ESCALATE_SUCCESS:'Quote Escalated',

            QUOTE_WORKFLOW_ALARM: 'Edit Alarm',
            QUOTE_WORKFLOW_ALARM_SUCCESS: 'Alarm Updated',
            QUOTE_WORKFLOW_ALARM_SUBMIT: 'Update',

            QUOTE_WORKFLOW_ALARM_SETNEW: 'Set Alarm',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarm Set',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Set',

            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT: 'Postpone Action',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUBMIT: 'Postpone',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUCCESS: 'Quote Postponed',

            QUOTE_WORKFLOW_REJECT_EXTENSION: 'Reject Extension',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Reject',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION: 'Accept Extension',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accept',
            QUOTE_WORKFLOW_CANCEL_EXTENSION: 'Cancel Extension',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Cancel Extension',
            QUOTE_WORKFLOW_REVISION_REQUEST: 'Request Revision',
            QUOTE_STATUS_REVISION_REQUESTED: 'Revision Requested',
            QUOTE_PROVIDE_REVISION_NOTES: 'Please provide revision notes',
            QUOTE_WORKFLOW_REQUEST_EXTENSION: 'Request Extension',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Submit',
            QUOTE_WORKFLOW_ACCEPT_VALUES: 'Accept Values',
            QUOTE_WORKFLOW_ACCEPT_VALUES_SUCCESS: 'Quote Values Accepted',
            QUOTE_WORKFLOW_REJECT_SUCCESS: 'Quote Rejected',
            QUOTE_WORKFLOW_REJECT_VALUES: 'Reject',
            QUOTE_WORKFLOW_REJECT_VALUES_SUCCESS: 'Quote Rejected',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Quote Extension Accepted',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Quote Extension Rejected',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Quote Extension Requested',
            QUOTE_WORKFLOW_REQUEST_REVISION_SUCCESS: 'Quote Revision requested',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Quote Extension Cancelled',
            REQUESTED_EXTENSION: 'Requested Extension',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUCCESS: 'Quote Approval Escalated',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUCCESS: 'Request More Information Success',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_TODAY: 'New Completion Date Must Be After Today',
            COMPLETION_DATE_CANNOT_BE_IN_PAST: 'Completion date cannot be in the past',
            ATTENDANCE_DATE_CANNOT_BE_IN_PAST: 'Attendance date cannot be in the past',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_CURRENT_COMPLETION_DATE: 'New Completion Date Must Be After Current Completion Date',
            QUOTE_APPROVER_NOT_SET: 'No quote approver is set, unable to submit the quote',
            MUST_BE_HELPDESK_OR_APPROVER_TO_ACCEPT_QUOTE: 'You must be helpdesk or approver to approve this quote',
            MUST_BE_HELPDESK_OR_APPROVER_OR_HAVE_PERMISSION_TO_ACCEPT_QUOTE_VALUES: 'You must be helpdesk or approver or have permission to approve this quote values',
            QUOTE_WORKFLOW_REASSIGN_ASSIGNMENT: 'Reassign Assignment',
            QUOTE_WORKFLOW_REASSIGN_OPERATIVE_SUBMIT: 'Reassign',
            QUOTE_WORKFLOW_REASSIGN_OPERATIVE: 'Reassign Quote Operative',
            QUOTE_WORKFLOW_REASSIGN_OPERATIVE_SUCCESS: 'Operative Reassigned',

            QUOTE_STATUS_CREATED: 'Created',
            QUOTE_STATUS_REQUESTED: 'Requested',
            QUOTE_STATUS_SUBMITTED: 'Pending Approval',
            QUOTE_STATUS_ACCEPTED: 'Accepted',
            QUOTE_STATUS_REJECTED: 'Rejected',
            QUOTE_STATUS_CANCELLED: 'Cancelled',
            QUOTE_STATUS_EXPIRED: 'Expired',
            QUOTE_STATUS_ABSTAINED: 'Declined to Quote',
            QUOTE_STATUS_PENDING: 'On Hold',
            QUOTE_STATUS_INFORMATION_REQUESTED: 'More Info Requested',
            QUOTE_STATUS_EXTENSION_REQUESTED: 'Extension Requested',
            QUOTE_STATUS_VALUE_PENDING_APPROVAL: 'Pending Value Approval',
            QUOTE_STATUS_BUDGET_QUOTE_SUBMITTED: 'Budget Quote Submitted',
            QUOTE_STATUS_BUDGET_QUOTE_CONVERTED: 'Converted',

            QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the Quote.',
            BUDGET_QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the Budget Quote.',
            QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> submitted the Quote.',
            QUOTE_VALUES_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent quote options for value approval.',
            QUOTE_MORE_INFORMATION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> requested more information.',
            QUOTE_ABSTAINED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> declined to quote.',
            QUOTE_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reopened the quote.',
            QUOTE_PUT_ON_HOLD_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> put the quote on hold.',
            QUOTE_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> resent an approval request to {{ ::event._links["approver"].title }}.',
            QUOTE_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the quote approval.',
            QUOTE_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> changed the Action Required By date to {{ ::event.reminderAt | date:\'short\' }}.',
            BUDGET_QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> submitted the Budget Quote.',
            QUOTE_REVISION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> requested a revision.',

            QUOTE_OPTION_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated quote option.',

            QUOTE_APPROVAL_RESENT: 'Quote approval request resent.',
            QUOTE_WORKFLOW_RESEND_APPROVAL: 'Resend Approval Request',

            QUOTE_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the quote.',
            QUOTE_ESCALATION_ADDED_SYSTEM_ACTION: 'The system automatically escalated the quote.',
            QUOTE_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed the quote from escalation.',

            QUOTE_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the quote.',
            QUOTE_ESCALATED_SYSTEM_ACTION: 'The system automatically escalated the quote.',
            QUOTE_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed the quote from escalation.',
            QUOTE_DEESCALATED_SYSTEM_ACTION: 'The system automatically removed the quote from escalation.',
            QUOTE_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the escalation.',

            QUOTE_WORKFLOW_ESCALATE_APPROVAL: 'Escalate Approval',

            QUOTE_CHASED_ACTION: 'A chase #{{ ::event.chaseSequence + 1 }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',

            QUOTE_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated the alarm.',

            QUOTE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> provided an update.',

            QUOTE_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> requested an extension.',
            QUOTE_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the extension request.',
            QUOTE_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the extension request.',
            QUOTE_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the extension request.',

            QUOTE_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent the audit trail.',

            QUOTE_APPROVAL_REQUIRED: 'Quote Approvals',
            REQUEST_MORE_INFORMATION_SUCCESS: 'Requested more information',

            QUOTE_REQUEST_REPORTER: 'Site Contact Details',
            PLEASE_SELECT_QUOTE_OPTIONS: 'Please select quote options',

            QUOTE_ACCEPTED: 'Quote Accepted',
            CANCEL_SUCCESS: 'Quote Cancelled',
            REOPEN_SUCCESS: 'Quote Reopened',
            ABSTAIN_SUCCESS: 'Quote Declined',
            SUBMIT_SUCCESS: 'Quote Submitted',
            REJECT_SUCCESS: 'Quote Rejected',
            HOLD_SUCCESS: 'Quote Held',
            DATE_DUE: 'Date due',
            DATE_IN: 'Date in',
            NO_SERVICE_PROVIDERS_FOR_SITE: 'No service providers for site',
            QUANTITY: 'Quantity',
            PO_NUMBER: 'PO Number',

            JOBTO: 'To Job',

            QUOTE_ACCEPTED_ACTION: 'Quote accepted by <strong>{{event._links.user.title}}</strong>',
            QUOTE_CANCELLED_ACTION: 'Quote cancelled by <strong>{{event._links.user.title}}</strong>',
            QUOTE_EXPIRED_SYSTEM_ACTION: 'This Quote has expired.',
            QUOTE_HOLD_ACTION: 'Quote put on hold by <strong>{{event._links.user.title}}</strong>',
            QUOTE_REJECTED_ACTION: 'Quote rejected by <strong>{{event._links.user.title}}</strong>',
            QUOTE_RFMI_ACTION: 'Request for more information by <strong>{{event._links.user.title}}</strong>',
            QUOTE_SUBMIT_ACTION: 'Quote submitted by <strong>{{event._links.user.title}}</strong>',
            QUOTE_ACTIONED_ACTION: 'Action required deadline was advanced.',
            QUOTE_FILE_ADDED: 'Quote file(s) added by <strong>{{event._links.author.title}}</strong>',
            QUOTE_VALUE_OPTIONS_ACCEPTED_ACTION: '<strong>{{event._links.user.title}}</strong> accepted Quote Value Options',
            QUOTE_VALUE_OPTIONS_REJECTED_ACTION: '<strong>{{event._links.user.title}}</strong> rejected Quote Value Options',
            QUOTE_OPERATIVE_REASSIGNED_ACTION: '<strong>{{event._links.user.title}}</strong> reassigned operative.',

            P2P: 'P2P',
            P2P_CIS: 'CIS',
            P2P_TAX_CODE: 'Tax Code',
            P2P_COMMENT: 'Comment',
            P2P_DESCRIPTION: 'Description',
            P2P_TRANSACTION: 'Transaction Type',
            P2P_SUPPLIER: 'Supplier',
            P2P_SCHEDULE_NUMBER: 'Schedule Number',
            P2P_NOMINAL_LINES: 'Nominal lines',
            P2P_EXPENSE_CODE: 'Expense Code',
            P2P_TENANT_CODE: 'Tenant Code',
            P2P_RATIO: 'Ratio (% of costs)',
            P2P_PO_SUPPLIER: 'P2P Supplier',
            ADD_LINE: 'Add line',
            REMOVE_LINE: 'Remove line',

            P2P_ERROR_QUOTE_PENDING: 'P2P Quote pending',
            P2P_ERROR_DESCRIPTION_TOO_LONG: 'Description exceeds 40 characters',
            P2P_ERROR_JOB_HAS_PO_NUMBER: 'The job already has a PO number',
            P2P_ERROR_INVALID_SITE_OR_USER: 'Invalid site or user',
            P2P_ERROR_RATIOS_INVALID: 'Ratios must be add up to 100 across all lines',

            NO_SEARCH_PROVIDED: 'No search provided',
            MISSING_ARGUMENT: 'Missing argument',

            QUOTE_OPTIONS_TAB_FILES: 'Please use the Quote Options tab to add files relating to an individual Quote Option',

            LOCATION_REQUIRED_QUOTE: 'Require Location for Reactive Quotes',

            QUOTE_ABSTAINED: 'Declined to quote',
            QUOTE_EXTENSION_CANCELLED: 'Quote extension requested cancelled',
            QUOTE_ACCEPTED_BY: 'Accepted By',
            QUOTE_EXTENSION_REQUESTED: 'Quote extension requested',
            QUOTE_EXTENSION_ACCEPTED: 'Quote extension accepted',
            QUOTE_EXTENSION_REJECTED: 'Quote extension rejected',
            QUOTE_SUBMITTED: 'Quote submitted',
            QUOTE_MESSAGE: 'Quote updates added',

            ORIGINATING_QUOTE_DETAILS: 'Originating Quote Details',

            NO_OPTIONS_ADDED: 'No Options Added',
            QUOTE_SHARED_OPTIONS: 'Quote Shared Options',
            QUOTE_OPTIONS_SUBMITTED_SUCCESS: 'Quote Options Submitted',
            QUOTE_NOT_SUBMITTED: 'Quote Not Submited',

            SUB_QUOTE: 'Sub Quote',
            AT_LEAST_ONE_OPTION_MUST_BE_SELECTED: 'At least one option must be selected',

            QUOTE_CANNOT_BE_SUBMITTED:'Quote Cannot Be Submitted',
            QUOTE_VALUE_APPROVER: 'Quote Value Approver',
            QUOTE_VALUES_APPROVAL_REQUESTED: 'Quote Values Approval Requested',
            QUOTE_VALUES_ACCEPTED: 'Quote Values Accepted',

            QUOTE_USER_NOT_FOUND: 'No users available with Quote Value Approval permission',
            SITE_ASSOCIATE_TYPE_IS_NOT_SET: 'Site Associate Type is not set',

            QUOTE_VALUE_CANNOT_BE_REQUESTED: 'Quote Values Cannot be Requested',
            QUOTE_VALUE_OPTIONS_CANNOT_BE_ACCEPTED: 'Quote Values Cannot be Accepted',
            QUOTE_VALUE_OPTIONS_CANNOT_BE_REJECTED: 'Quote Values Cannot be Rejected',
            QUOTE_VALUE_APPROVAL_NOT_ENABLED: 'Quote Values Approval Not Enabled',
            QUOTE_VALUE_APPROVED_BY: 'Value Approved by ',

            QUOTE_CURRENT_OPERATIVE: 'Current Operative',
            QUOTE_LINKS_TO: 'Quote Links To',
            QUOTE_LINKS_FROM: 'Quote Links From',

            FULL_QUOTE_REQUESTED: 'Full Quote Requested',

            QUOTE_CANNOT_HAVE_REVISION_REQUESTED: 'Quote cannot have revision requested'
        });
    }
})();
