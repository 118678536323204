(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.partnerships', {
                url: '/partnerships?partnershipPage&partnershipLimit&partnershipOrder&partnershipActive&contractorApproved&serviceProvidersStatus&serviceProvidersTelephone&serviceProvidersEmail&serviceProvidersAddress&serviceProviderGroups&partnerships&trainedAtStart&trainedAtEnd&activeAtStart&activeAtEnd&prosure360Status',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-left@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/partnerships/partnerships-list.html',
                        controller: 'UserSitePartnershipsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    partnershipOrder: 'service',
                    partnershipActive: null,
                    includeInactiveServiceProviders: 'true',
                    includeTrainingServiceProviders: 'true',
                    partnershipPage: '1',
                    partnershipLimit: '30',
                    contractorApproved: null,
                    name: null,
                    serviceProvidersTelephone: null,
                    serviceProvidersEmail: null,
                    serviceProvidersAddress: null,
                    serviceProviderGroups: {array: true},
                    serviceProvidersStatus: null,
                    partnerships: {array: true},
                    trainedAtStart: null,
                    trainedAtEnd: null,
                    activeAtStart: null,
                    activeAtEnd: null,
                    items: null,
                    prosure360Status: {array: true}
                },
                resolve: {
                    /**
                     * While unused, user is required as a dependency here to prevent race conditions against userManager.
                     * Do not remove it!
                     */
                    userResponse: function(user) {
                        return user;
                    },
                    partnershipsResponse: function(apiClient, siteResponse, $stateParams, paramsService) {
                        return apiClient.get(
                            siteResponse.getLink('partnerships'),
                            angular.extend({}, $stateParams, {
                                page: $stateParams.partnershipPage,
                                limit: $stateParams.partnershipLimit,
                                order: $stateParams.partnershipOrder,
                                active: $stateParams.partnershipActive,
                                'partnerships[]': $stateParams.partnerships,
                                'prosure360Status[]': paramsService.prosure360Status,
                                'serviceProviderGroups[]': $stateParams.serviceProviderGroups
                            })
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.partnerships',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/partnership/add/partnership-add.html',
                        controller: 'PartnershipAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sitePartnerships: function(apiClient, siteResponse, userResponse, rootResourceResponse) {
                        var link = userResponse.getLink('serviceproviders');
                        var params = {excludeSite: siteResponse.id};

                        if (rootResourceResponse.getLink('serviceproviders')) {
                            link = rootResourceResponse.getLink('serviceproviders');
                            params = {
                                excludeSite: siteResponse.id,
                                order: 'name',
                                status: 'active'
                            };
                        }

                        return apiClient.get(link, params
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details', {
                url: '/{partnershipResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.partnerships',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/partnership/tabs.html',
                        controller: 'PartnershipTabsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    partnershipResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.partnershipResource.decode());
                    },
                    serviceProviderResponse: function (apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('serviceprovider')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    priorityCreateType: function() {
                        return 'create-partnership-priority';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().partnershipResponse._links.serviceprovider.title }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/info/partnership-info.html',
                        controller: 'PartnershipInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.partnerships.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/partnership/edit/partnership-edit.html',
                        controller: 'PartnershipEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.partnerships.details.priorities',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-partnership-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.partnerships.details.priorities',
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.partnerships.details.priorities.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.partnerships.details.priorities.details.priorityinfo',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.surveys', {
                url: '/surveys',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/list/partnership-surveys.html',
                        controller: 'PartnershipSurveysController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysResponse: function (apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('surveys')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.surveys.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.partnerships.details.surveys',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/add/survey-add.html',
                        controller: 'PartnershipSurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), angular.extend({}, $stateParams, {
                            active: 'yes',
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_ADD" | translate }}'
                }
            })

            //Service Types
            .state('dashboard.user.sites.list.details.partnerships.details.service-types', {
                url: '/service-types',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.partnerships.details.service-types',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound()
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.contacts', {
                url: '/contacts',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/user/sites/details/partnerships/details/contacts/partnership-contacts.html',
                        controller: 'UserSitePartnershipDetailContactsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactInformationResponse: function(apiClient, serviceProviderResponse) {
                        return apiClient.get(serviceProviderResponse.getLink('contactinformation')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.performance', {
                url: '/performance',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/performance-statistics/performance-statistics.html',
                        controller: 'CommonPerformanceStatisticsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderPerformanceStatisticsResponse: function (apiClient, user, serviceProviderResponse, siteResponse) {
                        var params = {
                            serviceProvider: serviceProviderResponse.id,
                            site: siteResponse.id
                        };

                        return apiClient.get(user.getLink('performance-statistics'), params);
                    },
                    systemPerformanceStatisticsResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('systemperformancestatistics')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PERFORMANCE_STATISTICS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.user.sites.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-partnership-webhook';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.webhooks.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.partnerships.details.webhooks',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-partnership-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.webhooks.details', {
                url: '/details/{webhookResource}',
                parent: 'dashboard.user.sites.list.details.partnerships.details.webhooks',
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }} '
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.partnerships.details.webhooks.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.partnerships.details.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.partnerships.details.webhooks.details.webhooksinfo',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
