var app;

function parseBook(config) {
    for(var i in config) {
        if (i.indexOf('elogbooks-') > -1) {
            config[i.replace(/elogbooks-\w+/g, '')] = config[i];
        }
    }

    return config;
}

(function() {
    'use strict';

    let dependencies = [
        'ui.router',
        'ui.bootstrap',
        'ui.bootstrap.tpls',
        'ui.bootstrap.showErrors',
        'ui.select',
        'ui.calendar',
        'ui.bootstrap.datetimepicker',

        'as.sortable',
        'ncy-angular-breadcrumb',
        'utf8-base64',
        'pascalprecht.translate',
        'angularMoment',
        'elogbooksControllers',
        'elogbooksServices',
        'elogbooksDirectives',
        'elogbooksFilters',
        'ngLodash',
        'validation.match',
        'jcs.angular-http-batch',
        'ngSanitize',
        'ckeditor',
        'tmh.dynamicLocale',
        'angularLoad',
        'nvd3',
        'daterangepicker',
        'colorpicker-dr',
        'ngDragDrop',
        'vcRecaptcha',
        'rzSlider',

        'elogbooks.templates',
        'elogbooks.admin',
        'elogbooks.common',
        'elogbooks.user',
        'elogbooks.helpdesk',
        'MsalAngular',
        'ngMessages'
    ];

    //sentry diabled for dev env's
    if (!window.location.origin.match('local')) {
        dependencies.push('ngSentry')
    }

    app = angular.module('elogbooks', dependencies);

    angular.module('elogbooksControllers', []);
    angular.module('elogbooksServices', []);
    angular.module('elogbooksDirectives', []);
    angular.module('elogbooksFilters', []);
    angular.module('elogbooks.templates', []);

    app.config(function(base64){

        String.prototype.encode = function () {
            return base64.encode(this);
        };

        String.prototype.decode = function () {
            return base64.decode(this);
        };

        String.prototype.uriEncode = function () {
            var string = this;

            string = base64.encode(string);

            while (string != string.replace('=', '%3D')) {
                string = string.replace('=', '%3D');
            }
            return string;
        };

        Date.prototype.toRFC3339String = function () {
            return new moment(this).format('YYYY-MM-DDTHH:mm:ssZZ');
        };

    });

    // Configure batch requests
    app.config(function (httpBatchConfigProvider, API_URL) {
        httpBatchConfigProvider.setAllowedBatchEndpoint(API_URL, API_URL + '/batch', {
            maxBatchedRequestPerCall: 20,
            enabled: false
        });
    });

    app.config(function ($breadcrumbProvider) {
        $breadcrumbProvider.setOptions({includeAbstract: true});
    });

    app.config(function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    });

    app.config(function ($locationProvider) {
        $locationProvider.hashPrefix('');
    });

    app.config(function(tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('/translations/angular-locale_{{locale}}.js');
    });

    app.config(['msalAuthenticationServiceProvider', 'API_URL', '$windowProvider', function (msalProvider, API_URL, $windowProvider) {
        var clientId = 'undefined';

        if ($windowProvider.$get().localStorage.getItem('token')) {
            //clear old session data if there is any
            $windowProvider.$get().sessionStorage.clear();
        }

        const ssoParams = $windowProvider.$get().localStorage.getItem('ssoParameters');

        msalProvider.init({
            clientID: ssoParams ? JSON.parse(ssoParams).client_id : clientId,
            tokenReceivedCallback: function(errorDesc, token, error) {
                if (!error) {
                    $windowProvider.$get().localStorage.setItem('azureToken', token);
                }
            }
        });

        if (!ssoParams || JSON.parse(ssoParams)?.length === 0) {
            $.ajax({
                url: API_URL + '/sso/params',
            }).done(function (ssoParams) {
                $windowProvider.$get().localStorage.setItem('ssoParameters', JSON.stringify(ssoParams));

                if (undefined !== ssoParams && ssoParams.type === 'azure') {
                    clientId = ssoParams.client_id;
                }

                msal.clientId = clientId;
            });
        }
    }]);

    app.run(function (azureService, paramsService, $rootScope, $state, $translate, $window, $location, localeManager, disableSubmitService) {
        var timeRemaining = (parseInt($window.localStorage.getItem('tokenExpiration')) - new Date().getTime()) / 1000;
        if (timeRemaining < 0) {
            $window.localStorage.removeItem('token');
            $window.localStorage.removeItem('tokenExpiration');
        }

        // Disable submit on state change
        $rootScope.$on('$stateChangeStart', disableSubmitService.disableSubmit);
        $rootScope.$on('$stateNotFound', disableSubmitService.enableSubmit);
        $rootScope.$on('$stateChangeSuccess', disableSubmitService.enableSubmit);
        $rootScope.$on('$stateChangeError', disableSubmitService.enableSubmit);
        $rootScope.$on('$stateChangeAborted', disableSubmitService.enableSubmit);

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            if ($rootScope.preventDuplicateStateChangeTo === undefined) {
                $rootScope.preventDuplicateStateChangeTo = [];
            }

            $rootScope.preventDuplicateStateChangeTo.push(toState.name);

            if ($rootScope.preventDuplicateStateChangeTo && $rootScope.preventDuplicateStateChangeTo.length > 1 &&
                $rootScope.preventDuplicateStateChangeTo[0] === $rootScope.preventDuplicateStateChangeTo[1] &&
                !fromParams.ignoreEventPreventDefault) {
                event.preventDefault();
            }

            if ($rootScope.preventDuplicateStateChangeTo.length > 1) {
                $rootScope.preventDuplicateStateChangeTo.shift();
            }

            azureService.handleAzureLoginRedirect(event).then(function(response) {
                if (response && response.message) {
                    $rootScope.loginFailMsg = response.message;
                }

                $state.go('dashboard.user.dashboard', {}, {reload: true});
            });
        });

        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            $rootScope.preventDuplicateStateChangeTo = [];
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.preventDuplicateStateChangeTo = [];

            // Google analytics send page change event
            var host = $location.host();
            if (typeof $window.ga !== 'undefined'
                && host.indexOf('v2.elogbooks.net') !== -1
            ) {
                $window.ga('send', 'pageview', $location.path());
            }
        });

        $rootScope.bookerize = {
            enabled: false,

            toggle: function() {
                this.enabled = !this.enabled;

                if (this.enabled
                    && $state.current.name.indexOf('dashboard.user.jobs.details') > -1
                ) {
                    $state.go('dashboard.user.jobs.list.details.status', $state.current.params);
                }

                localStorage.setItem('bookerized', JSON.stringify(this.enabled));
            }
        };

        var locale = $window.localStorage.getItem('elogbooks.persist.locale');
        localeManager.set(locale);
    });
})();
