(function(){
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSiteListController', UserSiteListController);

    UserSiteListController.$inject = ['$stateParams', '$state', 'siteCollectionResponse', 'siteGroupCollectionResponse',
        'regionCollectionResponse', 'associateTypeCollectionResponse', 'siteAssociateCollectionResponse', '$filter',
        'rootResourceResponse', 'user', 'apiClient', 'paramConverterService', 'analyticsService', '$scope'];

    function UserSiteListController($stateParams, $state, siteCollectionResponse, siteGroupCollectionResponse,
             regionCollectionResponse, associateTypeCollectionResponse, siteAssociateCollectionResponse, $filter,
             rootResourceResponse, user, apiClient, paramConverterService, analyticsService, $scope) {

        var vm = this;
        vm.search = search;
        vm.collection = siteCollectionResponse;
        vm.sites = siteCollectionResponse.sites;
        vm.pages = siteCollectionResponse.pages;

        vm.loadedCollections = [siteGroupCollectionResponse, regionCollectionResponse, siteAssociateCollectionResponse, associateTypeCollectionResponse];

        var deregisterWatch = $scope.$watch('vm.sites', function(value) {
                analyticsService.pageLoaded();
                deregisterWatch();
        });

        vm.stateParams = $stateParams;
        vm.resources = {'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'user': user};

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        vm.criteria = {
            siteName: { type: 'text', title: 'NAME', value: $stateParams.siteName },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference },
            address: { type: 'text', title: 'ADDRESS', value: $stateParams.address }
        };

        if (siteGroupCollectionResponse) {
            vm.siteGroupSelect = {
                response : siteGroupCollectionResponse,
                link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'sitegroups'
            };
            vm.criteria.siteGroup = { type: 'jsonselectwidget', title: 'SITE_GROUPS', value: JSON.parse($stateParams.siteGroup), options: vm.siteGroupSelect };
        }

        if (regionCollectionResponse) {
            vm.criteria.siteRegions = { type: 'options', title: 'REGION', multiple: true, value: paramConverterService.checkStateParams($stateParams.siteRegions), options: getRegions()};
        }

        vm.associateTypeSelect = {};
        var selectedAssociatedType = null;

        if (associateTypeCollectionResponse) {
            selectedAssociatedType = $stateParams.siteAssociateType ? {
                title: JSON.parse($stateParams.siteAssociateType).n,
                value: JSON.parse($stateParams.siteAssociateType).v
            } : null;

            vm.associateTypeSelect = {
                response : associateTypeCollectionResponse,
                link : associateTypeCollectionResponse ? associateTypeCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'siteAssociateTypes',
                selected: selectedAssociatedType
            };
            vm.criteria.siteAssociateType = { type: 'jsonselectwidget', title: 'SITE_ASSOCIATE_TYPES', value: JSON.parse($stateParams.siteAssociateType) || null, options: vm.associateTypeSelect };
        }

        vm.associateSelect = {};

        if (!!siteAssociateCollectionResponse?.users) {
            var selectedAssociate = $stateParams.associate ? {
                title: JSON.parse($stateParams.associate).n,
                value: JSON.parse($stateParams.associate).v
            } : null;

            vm.associateSelect = {
                response : siteAssociateCollectionResponse,
                link : siteAssociateCollectionResponse ? siteAssociateCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'users',
                selected: selectedAssociate
            };
            vm.criteria.associate = { type: 'jsonselectwidget', title: 'SITE_ASSOCIATES', value: JSON.parse($stateParams.associate) || null, options: vm.associateSelect };
        }

        function search(params) {
            var override = {
                page:       1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

    }
})();
