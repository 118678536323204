(function () {
    /**
     * @desc ui.select expanded widget
     * @example <elb-autocomplete ng-model="vm.model"></elb-autocomplete>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbAutocomplete', ElbAutocomplete);

    ElbAutocomplete.$inject = ['$uibModal', '$timeout', 'messenger', 'apiClient', 'lodash'];

    function ElbAutocomplete ($uibModal, $timeout, messenger, apiClient, lodash) {
        return {
            restrict: 'AE',
            templateUrl: '/modules/directives/form-elements/autocomplete/autocomplete.html',
            scope: {
                model: '=ngModel',
                searchCtrl: '@',
                name: '@',
                searchUrl: '@',
                searchCtrlTemplate: '@',
                multiple: '@',
                displayIsMandatoryCheckbox: '@',
                max: '@',
                placeholder: '@',
                highlightedItems: '@?',
                orderBy: '@?'
            },
            require: ['ngModel'],
            link: function(scope, elem, attrs) {
                if('selectWidgetPlaceholder' in attrs) {
                    scope.placeholder = attrs.selectWidgetPlaceholder;
                }

                elem.bind('click', function () {
                    // Use timeout to wait for choices dropdown to render
                    $timeout(function() {
                        bindScroll();
                    }, 100);
                });

                function bindScroll() {
                    var dropdown = elem.find('.ui-select-dropdown');

                    dropdown.unbind('scroll');
                    dropdown.bind('scroll', function (e) {
                        var scrollMargin = 10;

                        if (e.target.scrollHeight - e.target.clientHeight - scrollMargin <= e.target.scrollTop) {
                            angular.element(e.target).scope().model.loadMore(e);
                        }
                    });
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.placeholder = $scope.placeholder || 'START_TYPING_TO_SEARCH';
                $scope.limitNotificationDisplayed = false;
                $scope.searched = false;
                $scope.expandSearch = expandSearch;
                $scope.removeSelectedElement = removeSelectedElement;

                if ($scope.model === null || typeof  $scope.model === 'undefined') {
                    $scope.model = { apiClientCache : 'short' };
                } else if ($scope.model && $scope.model.apiClientCache !== false) {
                    $scope.model.apiClientCache = 'short';
                } else {
                    $scope.model.apiClientCache = null;
                }

                var defaultModel = {
                    response : null,
                    link : null,
                    linkParameters: $scope.model.linkParameters ? $scope.model.linkParameters : {},
                    loading : false,
                    required : false,
                    disabled : false,
                    populateDisabled: false,
                    allowAdd : false,
                    multiple: false,
                    max: false,
                    tagging: false,
                    items : [],
                    selectedItems: [],
                    refreshOnLinkChange: true,
                    responseKeyPath: 'data',
                    itemHrefPath: '_links.self.href',
                    itemValuePath: 'name',
                    isHierarchical: false,
                    index: null,
                    appendToBody: false,
                    searchKey: 'name',
                    formatValue: null,
                    map : function(keyPath, valuePath) {
                        return function (item) {
                            if (typeof item === 'undefined'){
                                return;
                            }

                            var highlighted = [];

                            if ($scope.highlightedItems) {
                                highlighted = JSON.parse($scope.highlightedItems).filter(function(element) {
                                    return element === item.name;
                                });
                            }

                            return  {
                                object: item,
                                href :  lodash.get(item, keyPath),
                                value : lodash.get(item, valuePath),
                                id :  lodash.get(item, 'id'),
                                isMandatory :  lodash.get(item, 'isMandatory') ? lodash.get(item, 'isMandatory') : false,
                                highlighted: highlighted.length > 0
                            };
                        }
                    },
                    getResponseData : function (response) {
                        return lodash.get(response.getData(), this.responseKeyPath);
                    },
                    filterResponse : function (response) {
                        var responseData = this.getResponseData(response);

                        if (this.isHierarchical) {
                            var nofixed = this.processHierarchy(responseData, null),
                                prefixed = [];

                            function processChildren(items, prefix) {

                                prefix = prefix + ' / ';

                                angular.forEach(items, function (value, index) {
                                    lodash.set(value, $scope.model.itemValuePath, prefix + lodash.get(value, $scope.model.itemValuePath));
                                    prefixed.push(value);

                                    if (value.children.length) {
                                        processChildren(value.children, lodash.get(value, $scope.model.itemValuePath));
                                    }
                                });
                            }

                            angular.forEach(nofixed, function (value, index) {
                                prefixed.push(value);
                                if (value.children.length) {
                                    processChildren(value.children, lodash.get(value, $scope.model.itemValuePath));
                                }
                            });

                            return prefixed.map(this.map(this.itemHrefPath, this.itemValuePath));
                        }

                        return responseData.map(this.map(this.itemHrefPath, this.itemValuePath));
                    },
                    buildItems : function (response) {
                        this.items = this.filterResponse(response);
                    },
                    processHierarchy: function (collection, parent) {
                        var items = [], self = this;

                        angular.forEach(collection, function (value, index) {
                            if ((value.getLink('parent') === null && ! parent) || value.getLink('parent') === parent) {
                                var children = self.processHierarchy(collection, value.getLink('self'));

                                items.push(angular.extend({}, value, {children: children}));
                            }
                        });

                        return items;
                    },
                    search : function(keyword) {
                        this.loading = true;

                        if (!$scope.searched && keyword.length < 1) {
                            this.loading = false;
                            return;
                        }

                        if (keyword.length >= 1) {
                            $scope.searched = true;
                        }

                        if ((this.oldSearchKeyword && keyword !== this.oldSearchKeyword && keyword.length === 0)
                            || keyword.length >= 1 && this.link) {
                            var searchParams = {};
                            searchParams[this.searchKey] = keyword;

                            // Async function needs to access object properties from global scope
                            apiClient.get(this.link, angular.extend({}, this.linkParameters, searchParams), 'short').then(function (response) {
                                $scope.model.loading = false;

                                var filtered = $scope.model.filterResponse(response).filter(function (item) {
                                    return !lodash.find($scope.model.selectedItems, {'href': item.href});
                                });
                                $scope.model.items = filtered
                                $scope.model.response = response;
                                $scope.model.keyword = keyword;

                                addElement(keyword);
                            });
                        } else {
                            addElement(keyword);

                            this.loading = false;
                        }

                        this.oldSearchKeyword = keyword;
                    },
                    clear : function($event) {
                        if ($scope.model.disabled) {
                            return;
                        }

                        if (typeof $event !== 'undefined') {
                            $event.stopPropagation();
                        }

                        this.selected = undefined;
                        $scope.model.selected = undefined;

                        if (this.response
                            && $scope.model.items.length === 0
                            && !$scope.multiple
                        ) {
                            this.buildItems(this.response);
                        }

                        if (typeof this.onRemove == 'function') {
                            this.onRemove();
                        }
                    },
                    loadMore : function ($event) {
                        if (this.loading) {
                            return;
                        }

                        if ($scope.model.response.page === $scope.model.response.pages
                            && !$scope.limitNotificationDisplayed
                        ) {
                            $scope.limitNotificationDisplayed = true;
                        }

                        if ($scope.model.response.page < $scope.model.response.pages) {
                            $event.stopPropagation();
                            $event.preventDefault();

                            this.loading = true;

                            apiClient.get($scope.model.response.getLink('next'), this.linkParameters, 'short').then(function (response) {
                                const orderBy = $scope.orderBy || 'id';
                                $scope.model.items = lodash.uniqBy($scope.model.items, 'id');
                                $scope.model.items = lodash.orderBy($scope.model.items, [orderBy], ['asc']);
                                $scope.model.items = lodash.unionBy($scope.model.items, $scope.model.filterResponse(response), 'href').filter(function (item) {
                                    return !lodash.find($scope.model.selectedItems, {'href': item.href});
                                });;
                                response[$scope.model.responseKeyPath] = lodash.merge($scope.model.response[$scope.model.responseKeyPath], response[$scope.model.responseKeyPath]);
                                $scope.model.response = response;
                                $scope.model.loading = false;
                            });
                        }
                    }
                };

                function init () {
                    $scope.model.keyword = '';

                    if ($scope.model.items.length === 0
                        && $scope.model.response
                        && !$scope.multiple
                    ) {
                        $scope.model.buildItems($scope.model.response);
                    }

                    if ($scope.model.selected) {
                        if ($scope.multiple) {
                            var selectedItems = lodash.unionBy($scope.model.selectedItems, [$scope.model.selected]);
                            if ($scope.max > 0 && $scope.model.selectedItems.length >= $scope.max) {
                                $scope.maxlimit = true;
                                return;
                            } else {
                                $scope.maxlimit = false;
                            }

                            $scope.model.selectedItems = selectedItems;

                            // Clear selected value
                            this.selected = undefined;
                            $scope.model.selected = undefined;
                            // Remove from items
                            $scope.model.items = lodash.differenceBy($scope.model.items, $scope.model.selectedItems, 'href');
                        } else {
                            if (typeof $scope.model.selected.title !== 'undefined') {
                                $scope.model.selected.value = $scope.model.selected.title;
                            }
                        }
                    }

                    if ($scope.model.items.length === 0 && $scope.model.link) {
                        apiClient.get($scope.model.link, $scope.model.linkParameters, $scope.model.apiClientCache).then(function (response) {
                            $scope.model.items = lodash.unionBy($scope.model.items, $scope.model.filterResponse(response), 'href');
                            $scope.model.items = $scope.model.items.filter(function (item) {
                                return typeof lodash.find($scope.model.selectedItems, ['href', item.href]) == 'undefined';
                            });
                            $scope.model.response = response;
                            $scope.model.loadedAtLeastOneItem = $scope.model.items.length > 0;
                        });
                    }

                    $scope.model.requiredOriginal = $scope.model.required;
                }

                $scope.$watch('model.response', function (newValue, oldValue) {
                    if (newValue != oldValue && $scope.model.items.length !== 0) {
                        init();
                    }
                });

                $scope.$watch('model.selected', function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        init();
                    }
                });

                $scope.$watch('model.disabled', function(newValue) {
                    if (newValue && !$scope.model.populateDisabled) {
                        $scope.model.selected = undefined;
                    }

                    if ($scope.model.requiredOriginal) {
                        $scope.model.required = !newValue;
                    }
                });

                $scope.$watch('model.link', function(newValue, oldValue) {
                    if (newValue != oldValue && newValue && $scope.model.refreshOnLinkChange) {
                        $scope.model.loading = true;
                        return apiClient.get(newValue, $scope.model.linkParameters, 'short').then(function (itemsCollection) {
                            $scope.model.loading = false;
                            $scope.model.buildItems(itemsCollection);
                        });
                    }
                });

                function checkIfExist(keyword) {
                    for (var item in $scope.model.items) {
                        if ($scope.model.items.hasOwnProperty(item)
                            && $scope.model.items[item].value.indexOf(keyword) > -1
                        ) {
                            return true;
                        }
                    }

                    return false;
                }

                function addElement(keyword) {
                    if (keyword.length >= 1
                        && $scope.model.allowAdd
                        && !checkIfExist(keyword)
                    ) {
                        $scope.model.items.unshift({value:keyword});
                    }
                }

                function removeSelectedElement(object) {
                    $scope.model.selectedItems = lodash.differenceBy($scope.model.selectedItems, [object], 'href');
                    $scope.model.items = lodash.unionBy($scope.model.items, [object], 'href');
                }

                function getSearchTemplate() {
                    if ($scope.searchCtrlTemplate) {
                        return $scope.searchCtrlTemplate
                    }

                    switch ($scope.searchCtrl) {
                        case 'SearchSiteModalController':
                            return '/modules/common/modals/search/site/search-site-modal.html';
                    }
                }

                function expandSearch() {
                    if ($scope.searchCtrl) {
                        var modalInstance = $uibModal.open({
                            templateUrl: getSearchTemplate(),
                            controller: $scope.searchCtrl,
                            controllerAs: 'vm',
                            resolve : {
                                restrictOne : false,
                                searchUrl: function () {
                                    return $scope.searchUrl || '/sites';
                                },
                                linkParameters: function () {
                                    return $scope.model.linkParameters
                                }
                            }
                        });

                        modalInstance.result.then(function (items) {
                            if ($scope.multiple) {
                                $scope.model.selectedItems = lodash.unionBy($scope.model.selectedItems, items, 'href');
                                // Clear selected value
                                $scope.model.clear();
                                // Remove from items
                                $scope.model.items = lodash.differenceBy($scope.model.items, $scope.model.selectedItems, 'href');
                            } else {
                                var item = items[0];

                                $scope.model.items = lodash.differenceBy($scope.model.selectedItems, [item], 'href');
                                $scope.model.items.push(item);
                                $scope.model.selected = item;
                            }
                        });

                        this.expandedSearch = !this.expandedSearch;

                        if (this.expandedSearch) {
                            this.keyword = null;

                            if (typeof this.onRemove == 'function') {
                                this.onRemove();
                            }
                        }
                    }
                }

                //Merge with defaults
                $scope.model = lodash.merge(defaultModel, $scope.model);
                init();
            }]
        };
    }
})();
